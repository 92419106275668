/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import ExperienceRecord from "./ExperienceRecord.js"
import { workHistory } from "./WorkHistory"

const Experience = () => {
  return (
    <div css={styles}>
      <h2 className="section-hedaer">RELEVANT EXPERIENCE</h2>
      <hr className="exp-hr" />

      <div id="exp-container">
        {workHistory.map((rec, i) => (
          <ExperienceRecord key={i} expRec={rec} />
        ))}
      </div>
    </div>
  )
}

export default Experience

const styles = css`
  .exp-rec {
    display: flex;
    gap: 5px;
    justify-content: space-between;
  }

  .title {
    width: 40%;
  }

  .time-period {
    width: 24%;
  }

  .co-name {
    width: 15%;
  }

  .location {
    width: 18%;
  }

  .location {
    text-align: right;
  }

  .exp-desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 15px;
  }

  .exp-rec > h4,
  .exp-rec > div {
    display: flex;
    align-items: center;
    gap: 3px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  #exp-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  @media screen and (max-width: 600px) {
    .exp-desc-item {
      display: none;
    }

    div.time-period,
    div.location,
    .chevron,
    .exp-arrow {
      display: none;
    }

    h4.co-name {
      display: flex;
      align-items: flex-start;
      width: auto;
      min-width: 110px;
    }

    h4.title {
      flex-grow: 2;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: auto;
    }

    .previous-title {
      font-weight: 400;
    }
  }
`
