const divvy = {
  companyName: "Divvy",
  title: ["Software Engineer", "Software Engineer in Test"],
  timePeriod: "03/2021 – present",
  location: "Draper, Utah",
  description: [
    "Maintain and develop software testing framework (Elixir)",
    "Design, develop and execute automated software tests",
    "Create testing workflows & test cases for automated testing framework;",
    "Identify, isolate & document defects, errors, and inconsistencies in program functions;",
    "Professionally interact with development team in 'Agile' environment;",
  ],
}

const plansource = {
  companyName: "PlanSource",
  title: ["Software Test Engineer", "QA Engineer"],
  timePeriod: "07/2018 – 03/2021",
  location: "Salt Lake, Utah",
  description: [
    "Maintain and develop software testing framework (Ruby);",
    "Design, develop and execute automated software tests;",
    "Respond to test failures on CI/CD tool (Jenkins);",
    "Maintain Jenkins test suites;",
    "Perform Code Reviews for other SDET's and QA Engineers;",
    "Create testing workflows & test cases for automated testing framework;",
    "Identify, isolate & document defects, questionable functions, errors, and inconsistencies in program functions;",
    "Professionally interact with development team in 'Agile' environment;",
  ],
}

const xware = {
  companyName: "Xactware",
  title: ["Senior QA Analyst I", "QA Analyst II"],
  timePeriod: "10/2016 – 07/2018",
  location: "Lehi, Utah",
  description: [
    "Coordinate and lead QA effort on Testing processes, assigning tasks to QA Analysts as needed;",
    "Create testing workflows & test cases for automated testing framework;",
    "Write and run automated software tests (Swift);",
    "Perform manual testing on software products according to specifications and documentation;",
    "Identify, isolate and document defects, errors, and inconsistencies in software features;",
    "Create thorough test plans and test cases for manual testing;",
    "Create training materials, prepare and conduct employee training regarding Quality Assurance;",
    "Professionally interact with customers, clients, and other employees;",
  ],
}

export const workHistory = [divvy, plansource, xware]
