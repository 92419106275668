import {
  FaDatabase,
  FaRobot,
  FaGitAlt,
  FaCss3Alt,
  FaHtml5,
  FaExchangeAlt,
  FaRegGem,
  FaReact,
  FaNetworkWired,
} from "react-icons/fa"
import {
  SiJavascript,
  SiPostman,
  SiElixir,
  SiGraphql,
  SiAmazonaws,
  SiJest,
  SiJira,
} from "react-icons/si"
import { FiGitlab } from "react-icons/fi"
import { GrCycle } from "react-icons/gr"
import { AiOutlineProject } from "react-icons/ai"

const webDev = [
  [<FaReact size={19} />, "React JS"],
  [<SiJavascript />, "JavaScript"],
  [<FaHtml5 size={19} />, "HTML/JSX"],
  [<FaCss3Alt size={19} />, "CSS"],
  [<SiGraphql />, "GraphQL"],
  [<FaGitAlt size={19} />, "Git"],
  [<FaDatabase />, "SQL"],
  [<FaExchangeAlt />, "API"],
  [<SiElixir />, "Elixir"],
  [<FaRegGem />, "Ruby on Rails"],
]

const qa = [
  [<SiAmazonaws />, "AWS - SAA Certified"],
  [<SiJest />, "Jest / Unit Testing"],
  [<FaRobot />, "Test Automation"],
  "Cypress",
  "Selenium",
  [<SiPostman />, "API Testing"],
  [<FiGitlab />, "CI/CD tools"],
]

const other = [
  [<GrCycle />, "Agile Methodology"],
  [<SiJira />, "JIRA"],
  [<FaNetworkWired />, "Networking"],
  [<FaDatabase />, "Database Design"],
  [<AiOutlineProject />, "Project Management"],
]

export const skillData = [webDev, qa, other]
