/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import SkillColumn from "./SkillColumn"
import { skillData } from "./SkillData"

const Skills = () => {
  return (
    <div css={styles}>
      <h2 className="section-hedaer">SKILLS & QUALIFICATIONS</h2>
      <hr />

      <div id="container-skills">
        {skillData?.map((skills, i) => (
          <SkillColumn key={i} skills={skills} />
        ))}
      </div>
    </div>
  )
}

export default Skills

const styles = css`
  #container-skills {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  div[class="skills-column"] {
    display: flex;
    flex-direction: column;
  }

  div[class^="skills-column"] > div {
    display: flex;
    align-items: center;
    gap: 2px;
  }

  div[class^="skills-column"] svg {
    min-width: 20px;
  }
  div[class^="skills-column"] > h3 {
    margin-top: 3px;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 600px) {
    #container-skills {
      max-width: 600px;
      flex-direction: column;
      gap: 3px;
    }
  }
`
