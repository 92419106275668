/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FiExternalLink } from "react-icons/fi"
import { FaPhoneAlt, FaLinkedin, FaEnvelope } from "react-icons/fa"

const Header = () => {
  return (
    <div css={styles}>
      <h1 className="name">David Sarkisian</h1>

      <div className="contact-info">
        <div id="phone">
          <FaPhoneAlt size={20} />
          <div>+1 (385) 529-7370</div>
        </div>

        <div id="mail">
          <FaEnvelope size={20} />
          <div>sarkisiandv@gmail.com</div>
        </div>
        <div id="space"></div>
        <div id="linkedIn">
          <FaLinkedin size={21} />
          <div>
            <a
              href="https://www.linkedin.com/in/sarkisiandv"
              target="_blank"
              rel="noopener noreferrer"
            >
              sarkisiandv
            </a>
          </div>
          <FiExternalLink />
        </div>
      </div>
      <hr />
      {/* <div className="summary">
        Currently employed as a Software Engineer at Divvy.
        6 years of experience in Software QA, test automation, and front-end development.
        Recently graduated from the MSIS program at the University of Utah.
      </div> */}
    </div>
  )
}

export default Header

const styles = css`
  .contact-info {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .contact-info > div {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  h1.name {
    text-align: center;
    margin-bottom: 10px;
  }

  hr {
    border: 1px solid;
    background: black;
    margin-top: 1px;
  }

  #space {
    flex-grow: 3;
  }

  #linkedIn a {
    text-decoration: none;
  }

  h2 {
    margin-bottom: 5px;
  }

  @media screen and (max-width: 600px) {
    h1.name {
      text-align: initial;
    }
    .contact-info {
      margin-bottom: 10px;
      margin-left: 5px;
      flex-direction: column;
      align-items: flex-start;
    }

    #space {
      display: none;
    }
  }
`
