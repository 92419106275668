const SkillColumn = ({ skills }) => {
  return (
    <div className="skills-column">
      {skills?.map(
        (skill, i) =>
          (typeof skill === "string" && <div key={i}>{skill}</div>) || (
            <div key={i}>
              {skill[0]} <span>{skill[1]}</span>
            </div>
          )
      )}
    </div>
  )
}

export default SkillColumn
