const uOfU = {
  universityName: "University of Utah",
  fieldOfStudy: "Master of Science in Information Systems (MSIS)",
  gradYear: "2022",
  location: "Salt Lake City, Utah",
}

const devPointLabs = {
  universityName: "DevPoint Labs",
  fieldOfStudy: "Full-Stack Web Development",
  gradYear: "2019",
  location: "Salt Lake City, Utah",
}

const leti = {
  universityName: "St. Petersburg State Electro-Technical University",
  fieldOfStudy: "Bachelor of Science (BS) in Quality Management",
  gradYear: "2015",
  location: "Saint-Petersburg, Russia",
}

export const educationData = [uOfU, devPointLabs, leti]
