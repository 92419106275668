/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react"
import Header from "./Header"
import Education from "./Education/Education"
import Skills from "./Skills/Skills"
import Experience from "./Experience/Experience"

const PdfContainer = () => {
  return (
    <div css={styles}>
      <Header />
      <Education />
      <Experience />
      <Skills />
    </div>
  )
}

export default PdfContainer

const styles = css`
  font-family: "PT Serif", serif;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding-top: 1em;
  padding-left: 3em;
  padding-right: 3em;
  padding-bottom: 2em;
  width: 850px;
  margin: 10px;

  .section-hedaer {
    text-align: center;
  }

  @media screen and (max-width: 600px) {
    width: auto;

    .section-hedaer {
      text-align: center;
    }
  }
`
