import { useState } from "react"
import {
  FaChevronRight,
  FaChevronDown,
  FaCalendarAlt,
  FaMapMarkerAlt,
  FaLongArrowAltLeft,
} from "react-icons/fa"
import { VscArrowSmallRight } from "react-icons/vsc"

const ExperienceRecord = ({ expRec }) => {
  const { companyName, title, timePeriod, location, description } = expRec
  const [toggle, setToggle] = useState(companyName === "Divvy")

  const toggleSection = () => {
    setToggle(!toggle)
  }

  return (
    <>
      <div className="exp-rec">
        <h4 className="co-name">
          <div className="chevron">
            {(toggle && <FaChevronDown onClick={toggleSection} />) || (
              <FaChevronRight onClick={toggleSection} />
            )}
          </div>
          {companyName}
        </h4>
        <h4 className="title">
          {(typeof title === "string" && title) || (
            <>
              <span className="current-title">{title[0]}</span>
              <span className="exp-arrow">
                <FaLongArrowAltLeft />
              </span>
              <span className="previous-title">{title[1]} </span>
            </>
          )}
        </h4>
        <div className="time-period">
          <FaCalendarAlt />
          {timePeriod}
        </div>
        <div className="location">
          <FaMapMarkerAlt />
          {location}
        </div>
      </div>

      {toggle && (
        <div className="exp-desc">
          {description?.map((item, i) => (
            <div className="exp-desc-item" key={i}>
              <VscArrowSmallRight />
              {item}
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default ExperienceRecord
