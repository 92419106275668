/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FaUniversity, FaBook, FaCalendarAlt } from "react-icons/fa"
import { IoLocationSharp } from "react-icons/io5"
import EducationRecord from "./EducationRecord"
import { educationData } from "./EducationData.js"

const Education = () => {
  return (
    <div css={styles}>
      <h2 className="section-hedaer">EDUCATION</h2>
      <div id="education-table">
        <div id="header-row">
          <div className="column-header-uni">
            <FaUniversity size={21} />
            Institution
          </div>
          <div className="column-header-field">
            <FaBook />
            Degree
          </div>
          <div className="column-header-year">
            <FaCalendarAlt />
            Graduated
          </div>
          <div className="column-header-loc">
            <IoLocationSharp size={19} />
            Location
          </div>
        </div>

        {educationData.map((rec, i) => (
          <EducationRecord key={i} educationRecord={rec} />
        ))}
      </div>
    </div>
  )
}

export default Education

const styles = css`
  #education-table {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    background-color: black;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  #header-row,
  .education-record {
    background-color: black;
    display: flex;
    justify-content: space-evenly;
    gap: 2px;
    padding-left: 2px;
    padding-right: 2px;
    min-height: 45px;
  }

  div[class^="column-header"] {
    font-weight: 600;
  }

  div[class^="column-header"],
  div[class^="edu-record-"] {
    background-color: white;
    display: flex;
    align-items: center;
    gap: 2px;
  }

  div[class^="column-header"],
  .edu-record-year {
    justify-content: center;
  }

  .edu-record-uni,
  .edu-record-field,
  .edu-record-loc {
    justify-content: flex-start;
  }

  .education-record > div,
  div[class^="column-header"] {
    padding-left: 2px;
    padding-right: 2px;
  }

  .column-header-uni,
  .column-header-field,
  .edu-record-uni,
  .edu-record-field {
    flex-basis: 32%;
  }

  .column-header-loc,
  .column-header-year,
  .edu-record-loc,
  .edu-record-year {
    flex-basis: 18%;
  }

  @media screen and (max-width: 600px) {
    div.column-header-loc,
    div.edu-record-loc,
    div.column-header-year,
    div.edu-record-year {
      display: none;
    }

    div[class^="column-header"],
    div[class^="edu-record-"] {
      width: 50%;
      flex-grow: 2;
    }
  }
`
