const EducationRecord = ({ educationRecord }) => {
  const { universityName, fieldOfStudy, gradYear, location } = educationRecord

  return (
    <div className="education-record">
      <div className="edu-record-uni">{universityName}</div>
      <div className="edu-record-field">{fieldOfStudy}</div>
      <div className="edu-record-year">{gradYear}</div>
      <div className="edu-record-loc">{location}</div>
    </div>
  )
}

export default EducationRecord
